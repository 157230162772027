import { useEffect, useContext } from "react"
import { useLocation, useMatch } from "@reach/router"

import { ResponsiveContext } from "~config/responsive-provider"

export const useIntercom = () => {
    const {
        windowSize: { width },
    } = useContext(ResponsiveContext)
    const { pathname } = useLocation()
    const matchHomePage = pathname === "/"
    const matchHelpPage = !!useMatch("/help")

    useEffect(() => {
        const showIntercom = (matchHomePage || !!matchHelpPage) && width >= 768

        if (showIntercom) {
            Intercom("update", {
                hide_default_launcher: false,
                horizontal_padding: 40,
                vertical_padding: 40,
            })
        } else {
            Intercom("update", {
                hide_default_launcher: true,
                custom_launcher_selector: "#intercom-launcher",
            })
        }
    }, [pathname, width, matchHomePage, matchHelpPage])
}
