import React, { useEffect, useState } from "react"
import { useStyletron } from "baseui"

import SearchInput from "~components/shared/search-input/search-input"
import HeaderSearchButton from "~components/shared/header/header-search-button"
import { getUrlQueryParamsObj } from "~utils/helpers"

interface SearchBarProps {
    isCollapsed?: boolean
    clickHandler: () => void
}

const HeaderSearchBar: React.FC<SearchBarProps> = ({
    isCollapsed,
    clickHandler,
}) => {
    const { state, species, query, startDate, endDate } = getUrlQueryParamsObj()
    const [dates, setDates] = useState<Date[]>(
        startDate && endDate
            ? [
                  new Date(Number.parseInt(startDate as string)),
                  new Date(Number.parseInt(endDate as string)),
              ]
            : []
    )
    const styles = useStyles()

    useEffect(() => {
        if (startDate && endDate) {
            setDates([
                new Date(Number.parseInt(startDate as string)),
                new Date(Number.parseInt(endDate as string)),
            ])
        } else {
            setDates([])
        }
    }, [startDate, endDate])

    if (isCollapsed) {
        return <HeaderSearchButton handleClick={clickHandler} />
    }

    return (
        <div className={styles.searchContainer}>
            <div className={styles.inputWrapper}>
                <SearchInput
                    query={
                        (query as string) ||
                        (species as string) ||
                        (state as string)
                    }
                    dates={dates}
                    setDates={setDates}
                />
            </div>
        </div>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        searchContainer: css({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            paddingTop: "14px",
            maxWidth: "775px",
        }),
        inputWrapper: css({
            width: "100%",
            marginBottom: "12px",
            [theme.mediaQuery.medium]: {
                width: "100%",
                marginBottom: 0,
            },
        }),
    }
}

export default HeaderSearchBar
