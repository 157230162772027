import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticQuery, graphql } from "gatsby"
import { useStyletron } from "baseui"

const SignInImage = () => {
    const styles = useStyles()

    return (
        <StaticQuery
            query={graphql`
                {
                    file(relativePath: { eq: "sign-in.jpg" }) {
                        childImageSharp {
                            gatsbyImageData(width: 800, layout: CONSTRAINED)
                        }
                    }
                }
            `}
            render={(data) => (
                <GatsbyImage
                    alt="sign_in"
                    image={data.file.childImageSharp.gatsbyImageData}
                    className={styles.image}
                />
            )}
        />
    )
}

function useStyles() {
    const [css] = useStyletron()

    return {
        image: css({
            height: "100%",
            width: "100%",
        }),
    }
}

export default SignInImage
