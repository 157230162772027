import { CSSProperties } from "react"
import {
    format,
    isAfter,
    addDays,
    startOfDay,
    differenceInDays,
} from "date-fns"
import head from "lodash/head"
import filter from "lodash/filter"
import replace from "lodash/replace"
import queryString from "query-string"
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz"

import {
    Address,
    ListingType,
    LodgingType,
    BedType,
    PricingOption,
    PricingPackageFieldsFragment,
    QuotePrice,
    QuotePriceCategory,
} from "~graphql/generated/graphql"
import { StyleObject } from "styletron-standard"
import Big from "big.js"

export function formatPhoneNumber(phoneNumber: string): string {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "")
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`
    }
    return phoneNumber
}

export function formatPrice(
    price: number | null | undefined,
    hideZeroCents: boolean = true
) {
    if (typeof price !== "number") return "N/A"

    const formattedPrice = `$${centsToDollars(price).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })}`
    return hideZeroCents ? replace(formattedPrice, ".00", "") : formattedPrice
}

export function formatPriceWithCents(
    price: number,
    hideZeroCents: boolean = true
) {
    const formattedPrice = `$${centsToDollars(price).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })}`
    return hideZeroCents ? replace(formattedPrice, ".00", "") : formattedPrice
}

export function centsToDollars(cents: number): number {
    const dollars = divide(cents, 100)

    return dollars
}

export function dollarsToCents(dollars: number): number {
    const cents = multiply(dollars, 100)

    return Math.round(cents)
}

export function add(num1: number, num2: number): number {
    return Big(num1).add(num2).toNumber()
}

export function subtract(total: number, amount: number): number {
    return Big(total).sub(amount).toNumber()
}

export function multiply(num1: number, num2: number): number {
    return Big(num1).mul(num2).toNumber()
}

export function divide(num1: number, num2: number): number {
    return Big(num1).div(num2).toNumber()
}

export function takePercentage(total: number, percent: number): number {
    return Big(total).mul(percent).div(100).toNumber()
}

export function getLowestPricingOption(
    pricingOptions?: Pick<PricingOption, "day_rate">[]
) {
    return pricingOptions?.reduce(
        (prev, curr) =>
            (prev?.day_rate || 0) < (curr?.day_rate || 0) ? prev : curr,
        head(pricingOptions)
    )
}

export function getLowestPricingPackage(
    pricingPackages?: Pick<PricingPackageFieldsFragment, "price">[]
) {
    return pricingPackages?.reduce(
        (prev, curr) => ((prev?.price || 0) < (curr?.price || 0) ? prev : curr),
        head(pricingPackages)
    )
}

export function validatePhoneNumber(phone: string): boolean {
    // strip phone number and check if its 10 or 11 digits, if it's 11 digits, check if it starts with 1
    const strippedPhone = phone.replace(/\D/g, "")
    return strippedPhone.length === 11 && strippedPhone[0] === "1"
}

export function getUrlQueryParamsObj() {
    if (isServerSide()) {
        return {}
    }
    const { search } = window.location
    return queryString.parse(search)
}

export function getUrlQueryParam(paramName: string) {
    const paramsObj = getUrlQueryParamsObj()
    const param = paramsObj[paramName]
    const isParamValid = typeof param === "string"

    return isParamValid ? param : null
}

// @ts-ignore
export function construcQueryParamsString(queryParamsObj) {
    return queryString.stringify(queryParamsObj)
}

export function getDayOfWeek(date: Date) {
    return format(date, "EEEE")
}

export function getDayOfMonth(date: Date) {
    return format(date, "d")
}

export function getMonthAbbr(date: Date) {
    return format(date, "MMM")
}

export function getDifferenceInDays(start: Date, end: Date) {
    return differenceInDays(end, start)
}

export const startOfDayWithTimeZone = (date: Date, timeZone: string): Date => {
    const inputZoned = utcToZonedTime(date, timeZone)
    const dayStartZoned = startOfDay(inputZoned)
    const dayStart = zonedTimeToUtc(dayStartZoned, timeZone)

    return dayStart
}

export function getListingTypeCamelcase(type = ListingType.Hunting) {
    switch (type) {
        case ListingType.Hunting:
            return "Hunting"
        case ListingType.Fishing:
            return "Fishing"
        case ListingType.BlastCast:
            return "Cast and Blast"
        default:
            return "Other"
    }
}

export function getLodgingTypeCamelcase(type: string) {
    switch (type) {
        case LodgingType.Tent:
            return "Tent"
        case LodgingType.Bedroom:
            return "Bedroom"
        case LodgingType.CommonArea:
            return "Common Area"
        default:
            return "Other"
    }
}

export function getBedTypeCamelcase(type: string) {
    switch (type) {
        case BedType.TwinBed:
            return "Twin Bed"
        case BedType.QueenBed:
            return "Queen Bed"
        case BedType.KingBed:
            return "King Bed"
        case BedType.SleepingBag:
            return "Sleeping Bag"
        case BedType.Hammock:
            return "Hammock"
        case BedType.Sofa:
            return "Sofa"
        default:
            return "Other"
    }
}

export function getCleanEnum(dirty: string): string {
    return dirty
        ? dirty
              .replace(/_/g, " ")
              .split(" ")
              .map((str) => str.charAt(0) + str.slice(1).toLowerCase())
              .join(" ")
        : ""
}

export function getListingShortLocation(
    location: Pick<Address, "city" | "state">
) {
    return `${location.city}, ${location.state}`
}

// Filters out dates before minDate
export function getAvailableListingDates({
    includeDates,
    minDate,
}: {
    includeDates: Date[]
    minDate: Date
}) {
    if (!minDate) return includeDates

    return filter(includeDates, (date) => isAfter(date, minDate))
}

// Returns an array of arrays (of length boolChecks.length + 1)
// split by the consecutive filters (boolChecks) passed in
// The last array is the items that don't satisfy any of the filters
// Ex. splitArrayWithFilters([1,2,3,4,5], [i => i < 3, i => i > 3]) => [[1,2],[4,5],[3]]
export const splitArrayWithFilters = <T>(
    array: T[],
    boolChecks: ((item: T) => boolean)[]
): T[][] => {
    const retArr: T[][] = [...boolChecks.map(() => []), []]

    array.forEach((item) => {
        const index = boolChecks.findIndex((check) => check(item))
        if (index === -1) {
            retArr[retArr.length - 1].push(item)
        } else {
            retArr[index].push(item)
        }
    })

    return retArr
}

// Conditional styletron
export const conditionalCss = (
    css: StyleObject,
    shouldApply?: boolean
): StyleObject => (shouldApply ? css : {})

// Conditional style prop
export const conditionalStyle = (
    style: CSSProperties,
    shouldApply?: boolean
): CSSProperties => (shouldApply ? style : {})

// Cleaner string logic, if string exists, return it with optional pretext and posttext
export const stringIfExists = (
    conditionalString: string | null | undefined,
    options?: { pretext?: string; posttext?: string }
): string => {
    const { pretext, posttext } = options || {}

    return conditionalString
        ? `${pretext || ""}${conditionalString}${posttext || ""}`
        : ""
}

// Ensures a string is a float for use with money math
export const ensureFloat = (value: string) =>
    value.includes(".") ? value : `${value}.00`

export function formatMoney(input: number) {
    return `$${input.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
}

export const getUTCNoon = (date: Date | number): Date => {
    let tempDate: Date = new Date(date)

    return new Date(
        Date.UTC(
            tempDate.getFullYear(),
            tempDate.getMonth(),
            tempDate.getDate(),
            12,
            0,
            0
        )
    )
}

export const convertTimezoneId = (timezoneId: string): string => {
    try {
        const now = new Date()

        const options = {
            timeZone: timezoneId,
            timeZoneName: "longGeneric" as "short",
        }
        const formatter = new Intl.DateTimeFormat("en-US", options)
        const [timeZone] = formatter
            .formatToParts(now)
            .filter((part) => part.type === "timeZoneName")
        return timeZone.value
    } catch (error) {
        return "Unknown time zone"
    }
}

// Not using user fragment to allow partial user objects.
export function getFullName(
    user:
        | { first_name?: string | null; last_name?: string | null }
        | null
        | undefined
) {
    if (!user?.first_name || !user?.last_name) return ""

    return `${user.first_name} ${user.last_name}`
}

export function formatCityAndState(
    location: { city: string; state: string } | null | undefined
) {
    const city = location?.city || "No city yet"
    const state = location?.state || "No state yet"

    return `${city}, ${state}`
}

export function getPricingPackageEndDate(
    startDate: Date,
    daysIncluded: number
): Date {
    return addDays(startDate, daysIncluded - 1)
}

export function getArrayOfCount(length: number): number[] {
    return Array.from({ length })
        .fill(null)
        .map((_, index) => index)
}

export function isServerSide() {
    return typeof window === "undefined"
}

export function mergePrices(prices: QuotePrice[]): QuotePrice[] {
    const bookingFee = prices.find(
        (p) => p.category === QuotePriceCategory.BookingFee
    )
    const taxes = prices?.find(
        (p) => p.category === QuotePriceCategory.TaxesAndOtherFees
    )

    const newTaxes = taxes
        ? {
              ...taxes,
              value: add(taxes.value, bookingFee?.value || 0),
              subsections: [
                  ...taxes.subsections,
                  {
                      name: "Booking fee",
                      value: bookingFee?.value || 0,
                  },
              ],
          }
        : null

    return (
        !!newTaxes
            ? [
                  ...prices.filter(
                      (p) =>
                          !p.category ||
                          ![
                              QuotePriceCategory.BookingFee,
                              QuotePriceCategory.TaxesAndOtherFees,
                          ].includes(p.category)
                  ),
                  newTaxes,
              ]
            : prices
    ) as QuotePrice[]
}
