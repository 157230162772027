import React from "react"
import {
    Button,
    SIZE,
    KIND,
    SHAPE,
    ButtonProps,
    ButtonOverrides,
} from "baseui/button"

const stylesOverrides: ButtonOverrides = {
    BaseButton: {
        style: ({ $theme }) => ({
            fontSize: "16px",
            fontWeight: 600,
            marginRight: $theme.sizing.scale300,
            paddingRight: $theme.sizing.scale500,
            paddingLeft: $theme.sizing.scale500,
            textDecoration: "none",
            display: "none",
            [$theme.mediaQuery.medium]: {
                display: "initial",
            },
            // ":hover": {
            //     backgroundColor: "transparent",
            // },
        }),
    },
}

const HeaderButton: React.FC<ButtonProps> = (props) => {
    return (
        <Button
            size={SIZE.compact}
            kind={KIND.tertiary}
            shape={SHAPE.pill}
            overrides={stylesOverrides}
            {...props}
        >
            {props.children}
        </Button>
    )
}

export default HeaderButton
