import React from "react"
import { Button, SIZE } from "baseui/button"

interface PrimaryGradientButtonProps {
    fullwidth?: boolean
    margin?: boolean
    onClick?: (args: any) => any
    isLoading?: boolean
    compact?: boolean
    disabled?: boolean
    children?: React.ReactNode
}

const PrimaryGradientButton: React.FC<PrimaryGradientButtonProps> = ({
    fullwidth = false,
    margin = true,
    onClick,
    isLoading = false,
    compact = false,
    disabled = false,
    children,
}) => {
    return (
        <Button
            type="submit"
            size={compact ? SIZE.compact : SIZE.default}
            overrides={getStyleOverrides(fullwidth, margin)}
            onClick={onClick}
            isLoading={isLoading}
            disabled={disabled}
        >
            {children}
        </Button>
    )
}

function getStyleOverrides(fullwidth: boolean, margin: boolean) {
    return {
        BaseButton: {
            style: ({ $theme }: any) => {
                const borderRadius = $theme.borders.radius300
                const backgroundImage =
                    "linear-gradient(90deg, rgba(246,94,56,1) 0%, rgba(246,138,57,1) 50%, rgba(246,94,56,1) 100%)"

                return {
                    fontWeight: 600,
                    width: fullwidth ? "100%" : "auto",
                    marginTop: margin ? $theme.sizing.scale600 : 0,
                    marginBottom: margin ? $theme.sizing.scale600 : 0,
                    paddingRight: $theme.sizing.scale800,
                    paddingLeft: $theme.sizing.scale800,
                    borderTopLeftRadius: borderRadius,
                    borderTopRightRadius: borderRadius,
                    borderBottomLeftRadius: borderRadius,
                    borderBottomRightRadius: borderRadius,
                    backgroundSize: "200% auto",
                    backgroundPosition: "right center",
                    boxShadow: "0 2px 8px 0 rgba(246,116,57,.4)",
                    backgroundImage,
                    ":hover": {
                        backgroundPosition: "left center",
                    },
                    ":disabled": {
                        opacity: 0.8,
                        backgroundPosition: "right center",
                        color: $theme.colors.contentInversePrimary,
                    },
                }
            },
        },
    }
}

export default PrimaryGradientButton
