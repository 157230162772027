import React from "react"
import { Link } from "gatsby"
import { useStyletron } from "baseui"

import TextLogoDark from "~images/logos/mb-logo-dark.svg"
import TextLogo from "~images/logos/mb-logo.svg"
import { useColorModeValue } from "~utils/use-color-mode"

interface Props {
    isWhite?: boolean
    isCentered?: boolean
}

const HeaderLogo: React.FC<Props> = ({ isWhite, isCentered }) => {
    const styles = useStyles()
    const logoSrc = useColorModeValue(TextLogo, TextLogoDark)

    return (
        <Link
            to="/"
            className={`${styles.link} ${isCentered ? styles.centered : ``}`}
        >
            <img src={logoSrc} width={196} alt="Mallard Bay Logo" />
        </Link>
    )
}

function useStyles() {
    const [css] = useStyletron()

    return {
        link: css({
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
        }),
        centered: css({
            justifyContent: "center",
        }),
        logo: css({
            color: "#f77c2b",
            fontSize: "24px",
            fontWeight: 800,
            textShadow: "none",
            backgroundImage:
                "linear-gradient(270deg, rgba(246,94,56,1) 0%, rgba(246,138,57,1) 55%, rgba(246,94,56,1) 100%)",
            backgroundSize: "200% auto",
            backgroundPosition: "right center",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            transition:
                "background-position .5s, color 0.2s ease-in-out, text-shadow 0.05s ease-in-out, background-image 0.2s ease-in-out",
            ":hover": {
                backgroundPosition: "left center",
            },
        }),
    }
}

export default HeaderLogo
