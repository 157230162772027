import { createTheme, createDarkTheme } from "baseui"

import { breakpoints, mediaQuery } from "~config/theme-responsive"

const primitives = {
    primaryFontFamily: "Nunito Sans",
}

const overrides = {
    breakpoints,
    mediaQuery,
    colors: {
        backgroundAlwaysGrey: "#1F1F1F",
    },
}

const lightThemePrimitives = {
    ...primitives,
    // TODO - customize
}

const lightThemeOverrides = {
    ...overrides,
    // TODO - customize
}

const darkThemePrimitives = {
    ...primitives,
    // TODO - customize
}

const darkThemeOverrides = {
    ...overrides,
    // TODO - customize
}

export const LightTheme = createTheme(lightThemePrimitives, lightThemeOverrides)

export const DarkTheme = createDarkTheme(
    darkThemePrimitives,
    darkThemeOverrides
)
