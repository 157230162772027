import React from "react"
import { Search } from "baseui/icon"
import { Button, ButtonProps, SHAPE } from "baseui/button"
import { useStyletron } from "baseui"

const SearchButton: React.FC<ButtonProps> = (props) => {
    const styles = useStyles()

    return (
        <div className={styles.root}>
            <Button
                type="submit"
                shape={SHAPE.pill}
                overrides={{
                    BaseButton: {
                        style: {
                            paddingTop: "12px",
                            paddingRight: "14px",
                            paddingBottom: "12px",
                            paddingLeft: "14px",
                            backgroundImage:
                                "linear-gradient(90deg, rgba(246,94,56,1) 0%, rgba(246,138,57,1) 50%, rgba(246,94,56,1) 100%)",
                            backgroundSize: "200% auto",
                            backgroundPosition: "right center",
                            boxShadow: "0 2px 12px 0 rgba(246,116,57,.4)",
                            ":hover": {
                                backgroundPosition: "left center",
                            },
                            ":disabled": {
                                opacity: 0.7,
                            },
                        },
                    },
                }}
                startEnhancer={() => <Search size={24} />}
                {...props}
            >
                Search
            </Button>
        </div>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        root: css({
            display: "none",
            alignItems: "center",
            [theme.mediaQuery.small]: {
                display: "flex",
            },
        }),
    }
}

export default SearchButton
