import React, { useContext } from "react"
import { useStyletron } from "baseui"
import { Tag, KIND, VARIANT } from "baseui/tag"
import { StatefulPopover, PLACEMENT } from "baseui/popover"

import LoadingSkeleton from "~components/shared/loading-skeleton"
import ProfileButtonMenu from "~components/shared/header/header-profile-button-menu"
import { UserContext } from "~config/user-provider"
import { popoverOverrides } from "../overrides"

const HeaderProfileButton: React.FC = () => {
    const styles = useStyles()
    const { user, userRoles, isFetching } = useContext(UserContext)

    const firstName = user?.first_name ?? ""

    return (
        <div className={styles.root}>
            {userRoles && userRoles.includes("admins") && (
                <Tag
                    closeable={false}
                    kind={KIND.orange}
                    variant={VARIANT.light}
                >
                    admin
                </Tag>
            )}
            <StatefulPopover
                focusLock
                placement={PLACEMENT.bottomRight}
                overrides={popoverOverrides}
                content={({ close }) => <ProfileButtonMenu close={close} />}
            >
                <div className={styles.button}>
                    {isFetching ? (
                        <LoadingSkeleton width={"50px"} height={"16px"} />
                    ) : (
                        firstName
                    )}
                    <Avatar />
                </div>
            </StatefulPopover>
        </div>
    )
}

const Avatar = () => {
    const styles = useStyles()
    const { user, isFetching } = useContext(UserContext)

    return (
        <div
            className={styles.avatar}
            style={{ backgroundImage: `url(${user ? user.avatar_url : ""})` }}
        >
            {isFetching && <LoadingSkeleton circle />}
        </div>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        root: css({
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        }),
        button: css({
            position: "relative",
            paddingTop: "1px",
            paddingRight: "1px",
            paddingBottom: "1px",
            paddingLeft: "16px",
            backgroundColor: theme.colors.backgroundPrimary,
            borderTopLeftRadius: "22px",
            borderTopRightRadius: "22px",
            borderBottomRightRadius: "22px",
            borderBottomLeftRadius: "22px",
            fontWeight: 600,
            fontSize: "15px",
            color: theme.colors.contentPrimary,
            textDecoration: "none",
            boxShadow: theme.lighting.shadow400,
            borderTopWidth: "1px",
            borderRightWidth: "1px",
            borderBottomWidth: "1px",
            borderLeftWidth: "1px",
            borderTopStyle: "solid",
            borderRightStyle: "solid",
            borderBottomStyle: "solid",
            borderLeftStyle: "solid",
            borderTopColor: theme.colors.borderOpaque,
            borderRightColor: theme.colors.borderOpaque,
            borderBottomColor: theme.colors.borderOpaque,
            borderLeftColor: theme.colors.borderOpaque,
            transition: "all 0.25s",
            lineHeight: "18px",
            display: "inline-flex",
            flexDirection: "row",
            alignItems: "center",
            ":hover": {
                boxShadow: theme.lighting.shadow500,
                cursor: "pointer",
            },
        }),
        avatar: css({
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "32px",
            width: "32px",
            borderTopLeftRadius: "50%",
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
            borderBottomLeftRadius: "50%",
            marginLeft: "10px",
        }),
        indicator: css({
            position: "absolute",
            top: "-4px",
            right: "-4px",
            backgroundColor: "#f65e38",
            color: "white",
            fontSize: "10px",
            fontWeight: 800,
            lineHeight: "16px",
            height: "16px",
            minWidth: "16px",
            textAlign: "center",
            boxShadow: `0px 0px 0px 1.5px rgb(255, 255, 255)`,
            borderTopLeftRadius: "50%",
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
            borderBottomLeftRadius: "50%",
        }),
    }
}

export default HeaderProfileButton
