import React, {
    createContext,
    useState,
    useContext,
    useEffect,
    useRef,
} from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { useStyletron } from "baseui"
import { RiSunLine, RiMoonClearFill } from "react-icons/ri"

import headerLinks from "~config/header-links"
import HeaderLogo from "~components/shared/header/header-logo"
import HeaderButton from "~components/shared/header/header-button"
import HeaderLoginButton from "~components/shared/header/header-login-button"
import HeaderProfileButton from "~components/shared/header/header-profile-button"
import HeaderSearchBar from "~components/shared/header/header-search-bar"
import ResponsiveBlock from "~components/shared/responsive-block"
import AuthModal from "~components/shared/auth/auth-modal/auth-modal"
import WelcomeModal from "~components/pages/welcome-modal"
import { ResponsiveContext } from "~config/responsive-provider"
import { ThemeStates, useThemeContext } from "~config/theme-provider"
import { useColorModeValue } from "~utils/use-color-mode"
import { useAuth } from "~utils/auth-hooks"

export const authForms = {
    SIGN_IN: "SIGN_IN",
    SIGN_UP: "SIGN_UP",
}

type AuthModalContextType = {
    openAuthModal: boolean
    setOpenAuthModal: React.Dispatch<React.SetStateAction<boolean>>
    authForm: any
    setAuthForm: React.Dispatch<React.SetStateAction<any>>
}

export const AuthModalContext = createContext<AuthModalContextType | undefined>(
    undefined
)

const Header: React.FC = () => {
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth()
    const [openAuthModal, setOpenAuthModal] = useState(false)
    const [authForm, setAuthForm] = useState(null)
    const [collapsed, setCollapsed] = useState(true)
    const { theme } = useThemeContext()
    const { windowSize, windowScroll } = useContext(ResponsiveContext)
    const styles = useStyles()
    const location = useLocation()
    const headerRef = useRef(null)
    const lightThemeColors =
        location.pathname === "/" &&
        windowScroll.y < 30 &&
        theme === ThemeStates.LIGHT

    const logIn = () => {
        // setOpenAuthModal(true)
        // setAuthForm(authForms.SIGN_IN)
        loginWithRedirect({
            appState: { returnTo: window.location.pathname },
        })
    }

    useEffect(() => {
        function handleClickInside(event: Event) {
            // @ts-ignore
            if (headerRef.current && headerRef.current.contains(event.target)) {
                if (location.pathname === "/")
                    // eslint-disable-next-line no-unused-expressions
                    windowScroll.y > 30 ? setCollapsed(true) : null
                else setCollapsed(true)
            }
        }
        document.addEventListener("mousedown", handleClickInside)

        return () => {
            document.removeEventListener("mousedown", handleClickInside)
        }
    }, [headerRef, windowScroll.y, location.pathname])

    useEffect(() => {
        if (location.pathname === "/") {
            // eslint-disable-next-line no-unused-expressions
            windowScroll.y < 30 ? setCollapsed(false) : setCollapsed(true)
        } else {
            setCollapsed(true)
        }
    }, [windowScroll.y, location.pathname])

    if (!windowSize.isMedium) {
        return (
            <ResponsiveBlock className={styles.fixedBlock}>
                <HeaderSearchBar clickHandler={() => {}} />
            </ResponsiveBlock>
        )
    }

    return (
        <>
            <div
                ref={headerRef}
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    height:
                        collapsed ||
                        (windowScroll.y < 30 && location.pathname === "/")
                            ? 0
                            : "100vh",
                    width:
                        collapsed ||
                        (windowScroll.y < 30 && location.pathname === "/")
                            ? 0
                            : "100vw",
                }}
            />
            <div
                className={`${styles.header} ${
                    collapsed ? styles.headerCollapsed : ``
                }`}
            />
            <ResponsiveBlock className={styles.fixedBlock}>
                <div className={styles.headerContent}>
                    <HeaderLogo isWhite={lightThemeColors} />
                    <HeaderSearchBar
                        isCollapsed={collapsed}
                        clickHandler={() => setCollapsed(false)}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {(isLoading || isAuthenticated) && (
                            <>
                                {headerLinks.authorized.map((headerLink, i) => (
                                    <HeaderLink
                                        key={i}
                                        headerLink={headerLink}
                                    />
                                ))}
                                <HeaderProfileButton />
                            </>
                        )}
                        {!isLoading && !isAuthenticated && (
                            <>
                                {/* <HeaderButton onClick={() => navigate("/how-it-works")}>
                            How it works
                        </HeaderButton> */}
                                <ThemeButton />
                                {headerLinks.unauthorized.map(
                                    (headerLink, i) => (
                                        <HeaderLink
                                            key={i}
                                            headerLink={headerLink}
                                        />
                                    )
                                )}
                                <HeaderLoginButton onClick={logIn} />
                            </>
                        )}
                    </div>
                    <AuthModalContext.Provider
                        value={{
                            openAuthModal,
                            setOpenAuthModal,
                            authForm,
                            setAuthForm,
                        }}
                    >
                        <AuthModal />
                    </AuthModalContext.Provider>
                    <WelcomeModal />
                </div>
            </ResponsiveBlock>
        </>
    )
}

const ThemeButton: React.FC = () => {
    const { theme, toggle } = useThemeContext()
    const styles = useStyles()

    return theme === ThemeStates.LIGHT ? (
        <RiMoonClearFill onClick={toggle} className={styles.icon} />
    ) : (
        <RiSunLine onClick={toggle} className={styles.icon} />
    )
}

interface LinkProps {
    headerLink: {
        external?: boolean
        path: string
        name: string
    }
}

const HeaderLink: React.FC<LinkProps> = ({ headerLink }) => {
    const styles = useStyles()

    if (headerLink.external) {
        return (
            <a href={headerLink.path} className={styles.link}>
                <HeaderButton>{headerLink.name}</HeaderButton>
            </a>
        )
    }

    return (
        <>
            <Link to={headerLink.path} className={styles.link}>
                <HeaderButton>{headerLink.name}</HeaderButton>
            </Link>
        </>
    )
}

function useStyles() {
    const location = useLocation()
    const [css, theme] = useStyletron()
    const { windowScroll } = useContext(ResponsiveContext)
    const scrolled = windowScroll.y > 30
    const bgColor = useColorModeValue(
        "rgba(255, 255, 255, 1)",
        "rgba(20, 20, 20, .75)"
    )
    const blur = useColorModeValue("none", "blur(24px)")

    return {
        header: css({
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            transition:
                "height 0.2s ease-in-out, background-color 0.2s ease-in-out, blur 0.2s ease-in-out",
            height: "88px",
            boxSizing: "border-box",
            backgroundColor:
                scrolled || location.pathname !== "/" ? bgColor : "transparent",
            boxShadow:
                scrolled || location.pathname !== "/"
                    ? "rgba(0, 0, 0, 0.06) 0px 1px 12px"
                    : "none",
            backdropFilter: scrolled ? blur : "none",
            WebkitBackdropFilter: scrolled ? blur : "none",
        }),
        headerCollapsed: css({
            height: "74px",
            paddingTop: "16px",
            paddingRight: "0px",
            paddingBottom: "16px",
            paddingLeft: "0px",
        }),
        fixedBlock: css({
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            boxSizing: "border-box",
        }),
        headerContent: css({
            width: "100%",
            height: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            transition: "all 0.2s ease-in-out",
            backdropFilter: scrolled ? "blur(16px)" : "blur(0px)",
            WebkitBackdropFilter: scrolled ? "blur(16px)" : "blue(0px)",
            [theme.mediaQuery.medium]: {
                height: "74px",
                backdropFilter: "none",
                WebkitBackdropFilter: "none",
            },
        }),
        link: css({
            textDecoration: "none",
        }),
        icon: css({
            fontSize: "21px",
            transition: "all .2s",
            marginRight: theme.sizing.scale600,
            color: theme.colors.contentPrimary,
            ":hover": {
                cursor: "pointer",
                color: theme.colors.contentTertiary,
            },
        }),
    }
}

export default Header
