import React, { type ReactNode } from "react"

interface Props {
    className?: string
    shouldRender: boolean
    children: ReactNode
}

export default function ConditionalRenderContainer({
    className,
    shouldRender = false,
    children = null,
}: Props) {
    if (!shouldRender) return null

    return <div className={className}>{children}</div>
}
