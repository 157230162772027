import React, { useContext } from "react"
import { useStyletron } from "baseui"

import SignUpImage from "~components/shared/auth/auth-modal/sign-up-image"
import SignInImage from "~components/shared/auth/auth-modal/sign-in-image"
import LogoImage from "~images/logo-full.png"
import { authForms, AuthModalContext } from "~components/shared/header/header"

const AuthModalImage = () => {
    const modalContext = useContext(AuthModalContext)
    const styles = useStyles()

    return (
        <div className={styles.wrapper}>
            {modalContext?.authForm === authForms.SIGN_UP && <SignUpImage />}
            {modalContext?.authForm === authForms.SIGN_IN && <SignInImage />}
            <img src={LogoImage} className={styles.logo} />
        </div>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        wrapper: css({
            position: "relative",
            display: "none",
            width: "350px",
            [theme.mediaQuery.medium]: {
                display: "initial",
            },
        }),
        logo: css({
            width: "156px",
            position: "absolute",
            top: "50px",
            left: "50%",
            transform: "translate(-50%, 0)",
        }),
    }
}

export default AuthModalImage
