import React from "react"
import { styled } from "baseui"

const StyledLink = styled<"a", { $colored: boolean; $underline: boolean }>(
    "a",
    ({ $theme, $colored, $underline }) => ({
        fontWeight: 600,
        color: $colored ? "#ff6c0d" : $theme.colors.contentPrimary,
        textDecoration: $underline ? "underline" : "none",
    })
)

const ExternalLink: React.FC<{
    to: string
    colored?: boolean
    underline?: boolean
    children: React.ReactNode
}> = ({ to, colored, underline, children }) => (
    <StyledLink
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        $colored={!!colored}
        $underline={!!underline}
    >
        {children}
    </StyledLink>
)

export default ExternalLink
