import React from "react"
import { FormControl as BasewebFormControl } from "baseui/form-control"

const FormControl: React.FC<{
    label: string
    caption?: string
    children: React.ReactNode
}> = ({ label, caption, children }) => {
    return (
        <BasewebFormControl
            label={label}
            caption={caption}
            overrides={{
                Label: { style: { fontWeight: 700 } }, // TODO: move to theme
            }}
        >
            {children}
        </BasewebFormControl>
    )
}

export default FormControl
