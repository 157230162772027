import React from "react"
import { useStyletron } from "baseui"

interface Props {
    onClick: () => void
}

const HeaderLoginButton: React.FC<Props> = ({ onClick }) => {
    const styles = useStyles()

    return (
        <div className={styles.button} onClick={onClick}>
            Sign up or log in
        </div>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        button: css({
            position: "relative",
            paddingTop: "8px",
            paddingRight: "16px",
            paddingBottom: "8px",
            paddingLeft: "16px",
            borderTopWidth: "1px",
            borderRightWidth: "1px",
            borderBottomWidth: "1px",
            borderLeftWidth: "1px",
            borderTopStyle: "solid",
            borderRightStyle: "solid",
            borderBottomStyle: "solid",
            borderLeftStyle: "solid",
            borderTopColor: theme.colors.borderOpaque,
            borderRightColor: theme.colors.borderOpaque,
            borderBottomColor: theme.colors.borderOpaque,
            borderLeftColor: theme.colors.borderOpaque,
            backgroundColor: theme.colors.backgroundPrimary,
            borderTopLeftRadius: "22px",
            borderTopRightRadius: "22px",
            borderBottomRightRadius: "22px",
            borderBottomLeftRadius: "22px",
            fontWeight: 600,
            fontSize: "15px",
            color: theme.colors.contentPrimary,
            textDecoration: "none",
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.18)",
            transition: "all 0.25s",
            lineHeight: "18px",
            display: "inline-block",
            ":hover": {
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.18)",
                cursor: "pointer",
            },
        }),
    }
}

export default HeaderLoginButton
