import React from "react"
import { styled } from "baseui"

const StyledDivider = styled<"div", { $hasText: boolean; $margin: boolean }>(
    "div",
    ({ $theme, $hasText, $margin }) => ({
        display: "flex",
        alignItems: "center",
        userSelect: "none",
        lineHeight: "14px",
        fontSize: "14px",
        color: $theme.colors.contentSecondary,
        marginTop: $margin ? $theme.sizing.scale800 : 0,
        marginBottom: $margin ? $theme.sizing.scale800 : 0,
        "::before": {
            content: '""',
            flex: 1,
            borderBottom: `1px solid ${$theme.colors.borderOpaque}`,
            marginTop: "1px",
            marginRight: $hasText ? "12px" : 0,
        },
        "::after": {
            content: '""',
            flex: 1,
            borderBottom: `1px solid ${$theme.colors.borderOpaque}`,
            marginTop: "1px",
            marginLeft: $hasText ? "12px" : 0,
        },
    })
)

const Divider: React.FC<{ text?: string; margin?: boolean }> = ({
    text = "",
    margin = false,
}) => (
    <StyledDivider $hasText={!!text} $margin={margin}>
        {text}
    </StyledDivider>
)

export default Divider
