export default {
    unauthorized: [
        // {
        //     external: true,
        //     path: "https://mallardbay-blog.netlify.app",
        //     name: "Blog",
        // },
        // {
        //     path: "/guide",
        //     name: "Guide an adventure",
        // },
    ],
    authorized: [
        // {
        //     path: "/outfitter",
        //     name: "Switch to outfitters",
        // },
        // {
        //     path: "/guide",
        //     name: "Guide an adventure",
        // },
    ],
}
