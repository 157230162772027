import React from "react"
import { GatsbySSR, navigate } from "gatsby"
import { Auth0Provider } from "@auth0/auth0-react"
import { polyfill } from "interweave-ssr"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "mapbox-gl/dist/mapbox-gl.css"

import ApolloProvider from "~apollo/provider"
import { UserProvider } from "~config/user-provider"
import { ResponsiveProvider } from "~config/responsive-provider"
import { ReactQueryProvider } from "~config/react-query-provider"
import { isServerSide } from "~utils/helpers"
import { AUTH_REDIRECT_ROUTE } from "~config/constants"

polyfill()

const WrapRootElement: GatsbySSR["wrapRootElement"] = ({ element }): any => {
    const redirectUri = !isServerSide()
        ? `${window?.location.origin}${AUTH_REDIRECT_ROUTE}`
        : ""

    return (
        <Auth0Provider
            domain={process.env.GATSBY_AUTH0_DOMAIN!}
            clientId={process.env.GATSBY_AUTH0_CLIENT_ID!}
            authorizationParams={{
                redirect_uri: redirectUri,
                audience: process.env.GATSBY_AUTH0_AUDIENCE,
            }}
            onRedirectCallback={(appState) => {
                navigate(appState?.returnTo || window.location.pathname, {
                    replace: true,
                })
            }}
        >
            <ApolloProvider>
                <ReactQueryProvider>
                    <ResponsiveProvider>
                        <UserProvider>{element}</UserProvider>
                    </ResponsiveProvider>
                </ReactQueryProvider>
            </ApolloProvider>
        </Auth0Provider>
    )
}

export default WrapRootElement
