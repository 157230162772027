import { states } from "~config/states"

export function getStateSuggestions(input: string) {
    if (input === "") return ["Louisiana", "Arkansas", "Texas", "Kansas"]

    return states
        .filter((state) => {
            const name = state.name.toLowerCase()
            const abbreviation = state.abbreviation.toLowerCase()
            if (
                name.includes(input.toLowerCase()) ||
                abbreviation.includes(input.toLowerCase())
            )
                return true
            return false
        })
        .map((state) => state.name)
        .slice(0, 3)
}
