import React, { useContext } from "react"
import { ORIENTATION, Calendar } from "baseui/datepicker"
import { useStyletron } from "baseui"
import { isSameDay } from "date-fns"

import { getAvailableListingDates } from "~utils/helpers"
import { ResponsiveContext } from "~config/responsive-provider"
import { Availability } from "~graphql/generated/graphql"

type AvailabilityCalendarProps = {
    minDate?: Date
    includeDates?: Date[]
    value?: Date[]
    onChange?: (...args: any[]) => any
    availabilityForCalendar?: Availability[]
    showLabel?: boolean
}

const AvailabilityCalendar: React.FC<AvailabilityCalendarProps> = ({
    minDate,
    includeDates,
    value,
    onChange,
    availabilityForCalendar = [],
    showLabel = false,
}) => {
    const { windowSize } = useContext(ResponsiveContext)

    const maxSlots = Math.max(
        ...availabilityForCalendar.map((a) => a.available_slots)
    )

    const availableDates = getAvailableListingDates({
        includeDates: includeDates || [],
        minDate: minDate || new Date(Date.now()),
    })

    return (
        <Calendar
            range
            minDate={minDate}
            includeDates={availableDates.length ? availableDates : undefined}
            orientation={ORIENTATION.horizontal}
            monthsShown={windowSize.isLarge ? 2 : 1}
            value={value}
            onChange={onChange}
            overrides={{
                CalendarHeader: { style: { backgroundColor: "transparent" } },
                MonthHeader: { style: { backgroundColor: "transparent" } },
                Day: {
                    style: {
                        fontWeight: 600,
                        height: showLabel ? "64px" : "auto",
                    },
                },
                DayLabel: { style: { fontSize: "11px" } },
            }}
            dateLabel={
                showLabel
                    ? (date) => {
                          const matchedDate = availabilityForCalendar.filter(
                              (a) => isSameDay(new Date(a.date), date)
                          )[0]

                          return matchedDate ? (
                              <div
                                  style={{
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                  }}
                              >
                                  <AvailabilityIndicator
                                      slots={matchedDate.available_slots}
                                      maxSlots={maxSlots}
                                  />
                              </div>
                          ) : (
                              `-`
                          )
                      }
                    : undefined
            }
        />
    )
}

const AvailabilityIndicator: React.FC<{ slots: number; maxSlots: number }> = ({
    slots,
    maxSlots,
}) => {
    const styles = useStyles()
    const [_css, theme] = useStyletron()

    return (
        <div
            className={styles.statusDot}
            style={{
                backgroundColor:
                    slots === 0
                        ? theme.colors.negative400
                        : slots / maxSlots < 0.2
                        ? theme.colors.warning400
                        : theme.colors.positive300,
            }}
        />
    )
}

function useStyles() {
    const [css] = useStyletron()

    return {
        statusDot: css({
            width: "6px",
            height: "6px",
            borderRadius: "50%",
        }),
    }
}

export default AvailabilityCalendar
