import React from "react"
import { GatsbySSR } from "gatsby"
import { SnackbarProvider, PLACEMENT } from "baseui/snackbar"

import Layout from "~components/shared/layout"
import { ThemeProvider } from "~config/theme-provider"

const WrapPageElement: GatsbySSR["wrapPageElement"] = ({
    element,
    props,
}): any => {
    // props provide same data to Layout as Page element will get
    // including location, data, etc - you don't need to pass it
    return (
        <ThemeProvider>
            <SnackbarProvider placement={PLACEMENT.bottom}>
                <Layout {...props}>{element}</Layout>
            </SnackbarProvider>
        </ThemeProvider>
    )
}

export default WrapPageElement
