import React from "react"
import { useLocation, useMatch } from "@reach/router"
import { Block, BlockProps } from "baseui/block"

interface Props extends BlockProps {
    className?: string
}

const ResponsiveBlock: React.FC<Props> = ({ children, ...props }) => {
    const { pathname } = useLocation()
    const isBlogHome = useMatch("/blog/*")
    const isNarrow = ["/search", "/booking", "/wishlists/", "/inbox"].some(
        (path) => pathname.includes(path)
    )
    const paddings = [
        "scale800",
        "scale800",
        "scale800",
        "scale800",
        "scale800",
    ]
    const maxWidth = isNarrow ? "auto" : isBlogHome ? "1400px" : "1280px"

    return (
        <Block paddingLeft={paddings} paddingRight={paddings} {...props}>
            <Block
                overrides={{
                    Block: {
                        style: {
                            maxWidth,
                            width: "100%",
                            marginTop: "0",
                            marginRight: "auto",
                            marginBottom: "0",
                            marginLeft: "auto",
                            transition: "all 0.2s ease-in-out",
                        },
                    },
                }}
            >
                {children}
            </Block>
        </Block>
    )
}

export default ResponsiveBlock
