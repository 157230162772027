export const breakpoints = {
    small: 480,
    medium: 768,
    large: 1024,
    xlarge: 1200,
    xxlarge: 1366,
}

export const mediaQuery = Object.keys(breakpoints).reduce((acc, key) => {
    return {
        ...acc,
        // @ts-ignore
        [key]: `@media screen and (min-width: ${breakpoints[key]}px)`,
    }
}, {})
