import React, { useEffect, useState } from "react"
import { format } from "date-fns"
import { useStyletron } from "baseui"
import { isBefore } from "date-fns"
import { Button, ButtonOverrides, KIND, SHAPE, SIZE } from "baseui/button"
import { Input, InputOverrides } from "baseui/input"
import { ArrowLeft, Delete, Search } from "baseui/icon"

import SearchDropdown from "./search-dropdown"
import AvailabilityCalendar from "~components/pages/listing/availability-calendar"
import {
    SearchSpeciesFieldsFragment,
    useSearchSpeciesQuery,
} from "~graphql/generated/graphql"
import { getStateSuggestions } from "./search-helpers"
import { navigate } from "gatsby"

interface Props {
    query?: string
    dates?: Date[]
    closeDrawer: () => void
}

const MobileMenu: React.FC<Props> = ({ query, dates, closeDrawer }) => {
    const styles = useStyles()
    const [input, setInput] = useState<string>(query || "")
    const { states, species } = useSuggestions(input)
    const [selectedDates, setSelectedDates] = useState<Date[]>(dates || [])
    const [activeStep, setActiveStep] = useState<number>(0)
    const [isSearching, setIsSearching] = useState<boolean>(false)

    function onCalendarChange(newDate: { date: Date[] | Date }) {
        if (Array.isArray(newDate.date)) {
            if (!selectedDates[0]) {
                setSelectedDates(newDate.date)
            } else if (isBefore(newDate.date[0], selectedDates[0])) {
                setSelectedDates(newDate.date)
            } else {
                if (selectedDates.length <= 2) {
                    setSelectedDates(newDate.date)
                } else {
                    setSelectedDates([selectedDates[0], ...newDate.date])
                }
            }
        } else {
            setSelectedDates([newDate.date])
        }
    }

    function resetValues() {
        setInput("")
        setSelectedDates([])
        setActiveStep(0)
    }

    function handleSearch() {
        if (input !== "" || selectedDates.length >= 2) {
            navigate(
                `/search?query=${input}${
                    selectedDates.length > 1
                        ? `&startDate=${selectedDates[0].getTime()}&endDate=${selectedDates[1].getTime()}`
                        : ""
                }`
            )
            closeDrawer()
        }
    }

    function handleLinks(query: string) {
        setIsSearching(false)
        setInput(query)
        activeStep === 0 && setActiveStep(1)
    }

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <Button
                    shape={SHAPE.circle}
                    kind={KIND.tertiary}
                    onClick={() => {
                        isSearching ? setIsSearching(false) : closeDrawer()
                    }}
                >
                    {isSearching ? (
                        <ArrowLeft size={24} />
                    ) : (
                        <Delete size={24} />
                    )}
                </Button>
            </div>
            {isSearching ? (
                <form
                    className={styles.suggestions}
                    onSubmit={(e) => {
                        e.preventDefault()
                        setIsSearching(false)
                        setActiveStep(1)
                    }}
                >
                    <Input
                        type="search"
                        value={input}
                        onChange={(e) => setInput(e.currentTarget.value)}
                        placeholder="Search locations, species, listings..."
                        overrides={getInputOverrides(styles)}
                        autoFocus
                    />
                    <div className={styles.suggestionList}>
                        <SearchDropdown
                            input={input}
                            states={states}
                            species={species}
                            clickHandler={handleLinks}
                        />
                    </div>
                </form>
            ) : (
                <div className={styles.list}>
                    <div
                        className={styles.card}
                        onClick={() => setActiveStep(0)}
                    >
                        <div className={styles.inactiveWrapper}>
                            <p
                                className={
                                    activeStep === 0
                                        ? styles.cardTitle
                                        : styles.inactiveTitle
                                }
                            >
                                Search
                            </p>
                            {activeStep === 0 ? (
                                <>
                                    {!!input && (
                                        <ClearButton
                                            onClick={() => setInput("")}
                                        />
                                    )}
                                </>
                            ) : (
                                <p className={styles.cardHelpText}>
                                    {!!input ? input : "Any"}
                                </p>
                            )}
                        </div>
                        {activeStep === 0 && (
                            <div className={styles.searchWrapper}>
                                <div
                                    className={styles.searchTrigger}
                                    onClick={() => setIsSearching(true)}
                                >
                                    <Search
                                        size={22}
                                        className={styles.searchIcon}
                                    />
                                    <p
                                        className={
                                            input
                                                ? styles.searchText
                                                : styles.searchPlaceholder
                                        }
                                    >
                                        {input ||
                                            "Search locations, species, listings..."}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div
                        className={styles.card}
                        onClick={() => setActiveStep(1)}
                    >
                        <div className={styles.inactiveWrapper}>
                            <p
                                className={
                                    activeStep === 1
                                        ? styles.cardTitle
                                        : styles.inactiveTitle
                                }
                            >
                                When
                            </p>
                            {activeStep === 1 ? (
                                <>
                                    {selectedDates.length > 0 && (
                                        <ClearButton
                                            onClick={() => setSelectedDates([])}
                                        />
                                    )}
                                </>
                            ) : (
                                <p className={styles.cardHelpText}>
                                    {selectedDates.length === 0
                                        ? "Add Dates"
                                        : `${format(
                                              selectedDates[0],
                                              "LLL do, yyyy"
                                          )}${
                                              selectedDates[1] &&
                                              selectedDates[1].getTime() !==
                                                  selectedDates[0].getTime()
                                                  ? " - " +
                                                    format(
                                                        selectedDates[1],
                                                        "LLL do, yyyy"
                                                    )
                                                  : ""
                                          }`}
                                </p>
                            )}
                        </div>
                        {activeStep === 1 && (
                            <AvailabilityCalendar
                                minDate={new Date()}
                                includeDates={[]}
                                value={selectedDates}
                                onChange={onCalendarChange}
                            />
                        )}
                    </div>
                </div>
            )}
            {!isSearching && (
                <div className={styles.footer}>
                    <Button kind={KIND.tertiary} onClick={resetValues}>
                        Clear all
                    </Button>
                    <Button
                        overrides={getButtonOverrides()}
                        startEnhancer={() => <Search size={18} />}
                        onClick={handleSearch}
                    >
                        Search
                    </Button>
                </div>
            )}
        </div>
    )
}

const ClearButton: React.FC<{
    onClick: () => void
}> = ({ onClick }) => {
    return (
        <Button kind={KIND.tertiary} size={SIZE.compact} onClick={onClick}>
            Clear
        </Button>
    )
}

function getButtonOverrides(): ButtonOverrides {
    return {
        BaseButton: {
            style: ({ $theme }) => ({
                backgroundColor: "#f77c2b",
                fontWeight: "600",
                paddingTop: $theme.sizing.scale500,
                paddingBottom: $theme.sizing.scale500,
                paddingLeft: $theme.sizing.scale700,
                paddingRight: $theme.sizing.scale800,
                borderTopLeftRadius: $theme.sizing.scale400,
                borderTopRightRadius: $theme.sizing.scale400,
                borderBottomLeftRadius: $theme.sizing.scale400,
                borderBottomRightRadius: $theme.sizing.scale400,
                ":hover": {
                    backgroundColor: "#D5520B",
                },
            }),
        },
    }
}

function getInputOverrides(styles: any): InputOverrides {
    return {
        Root: {
            style: ({ $theme }) => {
                return {
                    borderTopLeftRadius: $theme.sizing.scale400,
                    borderTopRightRadius: $theme.sizing.scale400,
                    borderBottomLeftRadius: $theme.sizing.scale400,
                    borderBottomRightRadius: $theme.sizing.scale400,
                    overflow: "hidden",
                    borderTopWidth: "1px",
                    borderRightWidth: "1px",
                    borderBottomWidth: "1px",
                    borderLeftWidth: "1px",
                    borderColor: $theme.colors.contentInverseTertiary,
                    fontSize: "16px",
                }
            },
        },
        InputContainer: {
            style: ({ $theme }) => {
                return {
                    paddingTop: $theme.sizing.scale200,
                    paddingBottom: $theme.sizing.scale200,
                    paddingLeft: $theme.sizing.scale400,
                    paddingRight: $theme.sizing.scale400,
                    backgroundColor: $theme.colors.backgroundPrimary,
                }
            },
        },
        Input: {
            style: ({ $theme }) => {
                return {
                    color: $theme.colors.contentPrimary,
                    fontSize: "16px",
                    fontWeight: "600",
                }
            },
        },
        Before: function Before() {
            return (
                <Search
                    size={24}
                    style={{
                        marginLeft: "6px",
                        alignSelf: "center",
                    }}
                />
            )
        },
    }
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        root: css({
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            position: "relative",
            backgroundColor: theme.colors.backgroundSecondary,
        }),
        header: css({
            paddingTop: theme.sizing.scale400,
            paddingLeft: theme.sizing.scale400,
            paddingRight: theme.sizing.scale400,
        }),
        list: css({
            flex: 1,
            paddingTop: theme.sizing.scale400,
            paddingBottom: theme.sizing.scale400,
            paddingLeft: theme.sizing.scale400,
            paddingRight: theme.sizing.scale400,
        }),
        card: css({
            display: "flex",
            overflow: "hidden",
            flexDirection: "column",
            backgroundColor: theme.colors.backgroundPrimary,
            borderTopLeftRadius: theme.sizing.scale600,
            borderTopRightRadius: theme.sizing.scale600,
            borderBottomLeftRadius: theme.sizing.scale600,
            borderBottomRightRadius: theme.sizing.scale600,
            boxShadow: theme.lighting.shadow400,
            marginBottom: theme.sizing.scale600,
        }),
        cardTitle: css({
            fontSize: "1.3rem",
            fontWeight: "bold",
        }),
        inactiveTitle: css({
            fontSize: "0.9rem",
            fontWeight: "600",
            color: theme.colors.contentTertiary,
        }),
        inactiveWrapper: css({
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: theme.sizing.scale800,
            paddingRight: theme.sizing.scale800,
        }),
        cardHelpText: css({
            fontSize: "0.9rem",
            fontWeight: "600",
        }),
        searchWrapper: css({
            paddingLeft: theme.sizing.scale800,
            paddingRight: theme.sizing.scale800,
            marginBottom: theme.sizing.scale800,
        }),
        searchTrigger: css({
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderTopWidth: "1px",
            borderRightWidth: "1px",
            borderBottomWidth: "1px",
            borderLeftWidth: "1px",
            borderTopStyle: "solid",
            borderRightStyle: "solid",
            borderLeftStyle: "solid",
            borderBottomStyle: "solid",
            borderTopColor: theme.colors.contentInverseTertiary,
            borderRightColor: theme.colors.contentInverseTertiary,
            borderBottomColor: theme.colors.contentInverseTertiary,
            borderLeftColor: theme.colors.contentInverseTertiary,
            borderTopLeftRadius: theme.sizing.scale400,
            borderTopRightRadius: theme.sizing.scale400,
            borderBottomRightRadius: theme.sizing.scale400,
            borderBottomLeftRadius: theme.sizing.scale400,
            paddingTop: theme.sizing.scale100,
            paddingLeft: theme.sizing.scale600,
            paddingRight: theme.sizing.scale600,
            paddingBottom: theme.sizing.scale100,
        }),
        searchIcon: css({
            marginRight: theme.sizing.scale400,
        }),
        searchText: css({
            fontSize: "0.9rem",
            fontWeight: "600",
        }),
        searchPlaceholder: css({
            fontSize: "0.9rem",
            fontWeight: "600",
            color: theme.colors.contentTertiary,
        }),
        suggestions: css({
            flex: 1,
            display: "flex",
            overflow: "hidden",
            flexDirection: "column",
            backgroundColor: theme.colors.backgroundPrimary,
            marginTop: theme.sizing.scale400,
            paddingTop: theme.sizing.scale600,
            paddingLeft: theme.sizing.scale600,
            paddingRight: theme.sizing.scale600,
            paddingBottom: theme.sizing.scale600,
            borderTopLeftRadius: theme.sizing.scale800,
            borderTopRightRadius: theme.sizing.scale800,
            boxShadow: theme.lighting.shadow400,
        }),
        suggestionList: css({
            // flex: 1,
            marginTop: theme.sizing.scale400,
            overflowY: "scroll",
            overflowX: "hidden",
            height: "350px",
            borderBottomColor: theme.colors.backgroundTertiary,
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
        }),
        footer: css({
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "space-between",
            boxShadow: theme.lighting.shadow400,
            backgroundColor: theme.colors.backgroundPrimary,
            paddingTop: theme.sizing.scale400,
            paddingBottom: theme.sizing.scale400,
            paddingLeft: theme.sizing.scale600,
            paddingRight: theme.sizing.scale600,
        }),
    }
}

function useSuggestions(value: string) {
    const [states, setStates] = useState<string[]>([])
    const [species, setSpecies] = useState<SearchSpeciesFieldsFragment[]>([])
    // TODO: Implement debounce for performance
    const { data } = useSearchSpeciesQuery({
        variables: { data: { text: value } },
    })

    useEffect(() => {
        setStates(getStateSuggestions(value))
        if (data) setSpecies(data.searchSpecies.slice(0, 3))
    }, [data, value])

    return { states, species }
}

export default MobileMenu
