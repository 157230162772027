import React, { useState, useContext } from "react"
import { useStyletron } from "baseui"
import { Input } from "baseui/input"
import { Button } from "baseui/button"
import { FlexGrid, FlexGridItem } from "baseui/flex-grid"

import FormControl from "~components/shared/form-control"
import SocialButton from "~components/shared/auth/auth-modal/social-button"
import Divider from "~components/shared/divider"
import { authForms, AuthModalContext } from "~components/shared/header/header"

const SignUpForm = () => {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const modalContext = useContext(AuthModalContext)
    const styles = useStyles()

    return (
        <div className={styles.root}>
            <SocialButton google text="Sign up with Google" />
            <SocialButton facebook hasMarginTop text="Sign up with Facebook" />
            <Divider text="or" margin />
            <form onSubmit={(e) => e.preventDefault()}>
                <FlexGrid
                    flexGridColumnCount={2}
                    flexGridColumnGap="scale600"
                    marginTop="-8px"
                    marginBottom="-8px"
                >
                    <FlexGridItem>
                        <FormControl label={"First name"} caption={""}>
                            <Input
                                id="fname-input"
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </FormControl>
                    </FlexGridItem>
                    <FlexGridItem>
                        <FormControl label={"Last name"} caption={""}>
                            <Input
                                id="lname-input"
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </FormControl>
                    </FlexGridItem>
                </FlexGrid>
                <FormControl label={"Email address"} caption={""}>
                    <Input
                        id="email-input"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FormControl>
                <FormControl label={"Password"} caption={""}>
                    <Input
                        id="password-input"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="6+ characters"
                    />
                </FormControl>
                <div className={styles.disclaimer}>
                    By clicking <strong>Agree and continue</strong> below, I
                    agree to Mallard Bay’s Terms of Service, Privacy Policy, and
                    default Notification Settings.
                </div>
                <Button
                    type="submit"
                    overrides={{
                        BaseButton: { style: { width: "100%" } },
                    }}
                >
                    Agree and continue
                </Button>
            </form>
            <div className={styles.question}>
                Already have an account?{" "}
                <span
                    className={styles.link}
                    onClick={() => modalContext?.setAuthForm(authForms.SIGN_IN)}
                >
                    Log in
                </span>
            </div>
        </div>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        root: css({
            paddingTop: "40px",
            paddingRight: "24px",
            paddingBottom: "40px",
            paddingLeft: "24px",
            [theme.mediaQuery.small]: {
                paddingTop: "40px",
                paddingRight: "64px",
                paddingBottom: "40px",
                paddingLeft: "64px",
            },
        }),
        disclaimer: css({
            fontSize: "12px",
            fontWeight: 300,
            lineHeight: "18px",
            marginBottom: "20px",
        }),
        question: css({
            fontSize: "15px",
            fontWeight: 300,
            color: theme.colors.contentSecondary,
            marginTop: "24px",
        }),
        link: css({
            fontWeight: 600,
            color: theme.colors.contentPrimary,
            textDecoration: "underline",
            ":hover": {
                cursor: "pointer",
            },
        }),
    }
}

export default SignUpForm
