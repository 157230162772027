import React from "react"
import fetch from "isomorphic-fetch"
import {
    ApolloProvider,
    ApolloClient,
    InMemoryCache,
    createHttpLink,
    ApolloLink,
} from "@apollo/client"

import { useAccessToken } from "~utils/auth-hooks"

const Provider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const accessToken = useAccessToken()

    const authMiddleware = new ApolloLink((operation, forward) => {
        operation.setContext({
            headers: accessToken
                ? { authorization: `Bearer ${accessToken}` }
                : {},
        })
        return forward(operation)
    })

    const httpLink = createHttpLink({
        uri: process.env.GATSBY_API_URL,
        credentials: "same-origin",
        fetch,
    })

    const client = new ApolloClient({
        link: authMiddleware.concat(httpLink),
        cache: new InMemoryCache({
            possibleTypes: { QuoteInterface: ["Quote", "DraftQuote"] },
        }),
        credentials: "include",
    })

    return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export const useAuthHeaders = () => {
    const accessToken = useAccessToken()

    return {
        authContext: {
            headers: accessToken
                ? { authorization: `Bearer ${accessToken}` }
                : {},
        },
        hasAccessToken: !!accessToken,
    }
}

export default Provider
