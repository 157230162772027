import React, { createContext, useState, useEffect } from "react"
import throttle from "lodash/throttle"

import { breakpoints } from "~config/theme-responsive"

interface IResponsiveContext {
    windowSize: IWindowSize
    windowScroll: IWindowScroll
}

interface IWindowSize {
    width: number
    height: number
    isMobile: boolean
    isSmall: boolean
    isMedium: boolean
    isLarge: boolean
    isXlarge: boolean
}

interface IWindowScroll {
    x: number
    y: number
    isBottom: boolean
}

export const defaultWindowSize = {
    width: 0,
    height: 0,
    isMobile: false,
    isSmall: false,
    isMedium: false,
    isLarge: true,
    isXlarge: true,
}

export const defaultWindowScroll = {
    x: 0,
    y: 0,
    isBottom: false,
}

export const ResponsiveContext = createContext<IResponsiveContext>({
    windowSize: defaultWindowSize,
    windowScroll: defaultWindowScroll,
})

export const ResponsiveProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    return (
        <ResponsiveContext.Provider value={{ ...useResponsive() }}>
            {children}
        </ResponsiveContext.Provider>
    )
}

function useResponsive() {
    const [windowSize, setWindowSize] = useState(defaultWindowSize)
    const [windowScroll, setWindowScroll] = useState(defaultWindowScroll)

    function getWindowSize() {
        const { innerWidth, innerHeight } = window

        return {
            width: innerWidth,
            height: innerHeight,
            isMobile: innerWidth < breakpoints.small, // less than small breakpoint
            isSmall: innerWidth >= breakpoints.small, // at least small breakpoint
            isMedium: innerWidth >= breakpoints.medium, // at least medium breakpoint
            isLarge: innerWidth >= breakpoints.large, // at least large breakpoint
            isXlarge: innerWidth >= breakpoints.xlarge, // at least xlarge breakpoint
        }
    }

    function getWindowScroll() {
        const { innerHeight, scrollX, scrollY } = window
        const { body } = document

        return {
            x: scrollX,
            y: scrollY,
            isBottom:
                body.offsetHeight > innerHeight
                    ? innerHeight + scrollY > body.offsetHeight - 1
                    : false,
        }
    }

    useEffect(() => {
        handleResize()
        handleScroll()

        function handleResize() {
            setWindowSize(getWindowSize())
        }

        function handleScroll() {
            setWindowScroll(getWindowScroll())
        }

        const handleResizeThrottled = throttle(handleResize, 250)
        const handleScrollThrottled = throttle(handleScroll, 250)

        window.addEventListener("resize", handleResizeThrottled)
        window.addEventListener("scroll", handleScrollThrottled)

        return () => {
            window.removeEventListener("resize", handleResizeThrottled)
            window.removeEventListener("scroll", handleScrollThrottled)
        }
    }, [])

    return { windowSize, windowScroll }
}
