import React from "react"

import { Search } from "baseui/icon"
import { useStyletron } from "baseui"
import { useColorModeValue } from "~utils/use-color-mode"

interface Props {
    handleClick: () => void
}

const HeaderSearchButton: React.FC<Props> = ({ handleClick }) => {
    const styles = useStyles()
    const iconColor = useColorModeValue("white", "black")

    return (
        <button className={styles.button} onClick={() => handleClick()}>
            Start your adventure
            <div className={styles.icon}>
                <Search size={24} color={iconColor} />
            </div>
        </button>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        button: css({
            width: "294px",
            height: "50px",
            paddingTop: "0",
            paddingRight: "8px",
            paddingBottom: "0",
            paddingLeft: "20px",
            borderTopWidth: "1px",
            borderRightWidth: "1px",
            borderBottomWidth: "1px",
            borderLeftWidth: "1px",
            borderTopStyle: "solid",
            borderRightStyle: "solid",
            borderBottomStyle: "solid",
            borderLeftStyle: "solid",
            borderTopColor: theme.colors.borderOpaque,
            borderRightColor: theme.colors.borderOpaque,
            borderBottomColor: theme.colors.borderOpaque,
            borderLeftColor: theme.colors.borderOpaque,
            backgroundColor: theme.colors.backgroundPrimary,
            borderTopLeftRadius: "26px",
            borderTopRightRadius: "26px",
            borderBottomRightRadius: "26px",
            borderBottomLeftRadius: "26px",
            fontWeight: 600,
            fontSize: "14px",
            color: theme.colors.contentPrimary,
            textDecoration: "none",
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.18)",
            transition: "all 0.25s",
            lineHeight: "18px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundPosition: "right center",
            ":hover": {
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.18)",
                cursor: "pointer",
                backgroundPosition: "left center",
            },
        }),
        icon: css({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius: "50%",
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
            borderBottomLeftRadius: "50%",
            height: "36px",
            width: "36px",
            backgroundSize: "200% auto",
            backgroundPosition: "inherit",
            backgroundImage:
                "linear-gradient(90deg, rgba(246,94,56,1) 0%, rgba(246,138,57,1) 50%, rgba(246,94,56,1) 100%)",
        }),
    }
}

export default HeaderSearchButton
