import React, { createContext, useState, useMemo } from "react"
import { ApolloError, MutationFunctionOptions } from "@apollo/client"
import { useSnackbar } from "baseui/snackbar"
import { Check, Alert } from "baseui/icon"

import { useAuth } from "~utils/auth-hooks"
import {
    useUserQuery,
    useUpdateUserMutation,
    useAssignOutfitterRoleMutation,
    UserFieldsFragment,
    UpdateUserMutation,
    UpdateUserInput,
    Exact,
} from "~graphql/generated/graphql"

interface IUserContext {
    user: UserFieldsFragment | undefined
    userRoles: string[]
    // functions
    updateUser: (
        options?: MutationFunctionOptions<
            UpdateUserMutation,
            Exact<{
                data: UpdateUserInput
            }>
        >
    ) => void
    userNewData: object
    setUserNewData: (data: object) => void
    assignOutfitterRole: () => void
    // booleans
    fetchCalled: boolean
    updateCalled: boolean
    isFetching: boolean
    isUpdating: boolean
    isImpersonating: boolean
    // error
    fetchError: ApolloError | undefined
    updateError: ApolloError | undefined
}

export const UserContext = createContext<IUserContext>({
    user: undefined,
    userRoles: [],
    // functions
    updateUser: () => {},
    userNewData: {},
    setUserNewData: () => {},
    assignOutfitterRole: () => {},
    // booleans
    fetchCalled: false,
    updateCalled: false,
    isFetching: false,
    isUpdating: false,
    isImpersonating: false,
    // error
    fetchError: undefined,
    updateError: undefined,
})

interface Props {
    children: React.ReactNode
}

export const UserProvider: React.FC<Props> = ({ children }) => {
    return (
        <UserContext.Provider value={{ ...useUser() }}>
            {children}
        </UserContext.Provider>
    )
}

function useUser(): IUserContext {
    const { enqueue } = useSnackbar() // TODO: this is not working due to User Context being outside of Snackbar Context
    const { isLoading: auth0Loading, authUser, accessToken } = useAuth()

    const [userNewData, setUserNewData] = useState({})
    const roles: string[] = useMemo(
        () => (authUser ? authUser["https://dev.mallardbay.com/roles"] : []),
        [authUser]
    )
    const isImpersonating = useMemo(
        () =>
            authUser?.["https://dev.mallardbay.com/acts_as_user_id"]
                ? true
                : false,
        [authUser]
    )

    const {
        called: fetchCalled,
        loading: fetchLoading,
        error: fetchError,
        data: fetchData,
    } = useUserQuery({
        skip: !accessToken,
        context: {
            headers: accessToken
                ? { authorization: `Bearer ${accessToken}` }
                : {},
        },
    })

    const [
        updateUser,
        { called: updateCalled, loading: updateLoading, error: updateError },
    ] = useUpdateUserMutation({
        variables: { data: userNewData },
        context: {
            headers: accessToken
                ? { authorization: `Bearer ${accessToken}` }
                : {},
        },
        onCompleted: () => {
            enqueue({
                // eslint-disable-next-line react/display-name, react/prop-types
                startEnhancer: ({ size }) => <Check size={size} />,
                message: "User profile updated successfully",
            })
        },
        onError: (err) => {
            enqueue({
                // eslint-disable-next-line react/display-name, react/prop-types
                startEnhancer: ({ size }) => <Alert size={size} />,
                message: err.message,
            })
        },
    })

    const [assignOutfitterRole, { loading: assignLoading }] =
        useAssignOutfitterRoleMutation({
            onCompleted: () => roles.push("outfitters"),
        })

    return {
        user: fetchData?.user,
        userRoles: roles,
        // functions
        updateUser,
        userNewData,
        setUserNewData,
        assignOutfitterRole,
        // booleans
        fetchCalled,
        updateCalled,
        isFetching: auth0Loading || fetchLoading,
        isUpdating: updateLoading || assignLoading,
        isImpersonating,
        // error
        fetchError,
        updateError,
    }
}
