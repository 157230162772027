import React, { useState, useEffect, useContext, useMemo } from "react"
import { Input, MaskedInput } from "baseui/input"
import { Notification, KIND } from "baseui/notification"
import { ToastOverrides } from "baseui/toast"

import MbModal from "~components/shared/mb-modal"
import FormControl from "~components/shared/form-control"
import PrimaryGradientButton from "~components/shared/primary-gradient-button"
import { UserContext } from "~config/user-provider"
import { validatePhoneNumber } from "~utils/helpers"

const notificationOverrides: ToastOverrides = {
    Body: {
        style: ({ $theme }) => {
            return {
                width: "auto",
                color: $theme.colors.contentOnColor,
                backgroundColor: $theme.colors.negative,
            }
        },
    },
}

const WelcomeModal = () => {
    const [isOpen, setIsOpen] = useState(false)
    const { user, updateUser, setUserNewData, isUpdating, updateError } =
        useContext(UserContext)
    const [firstName, setFirstName] = useState<string>(user?.first_name || "")
    const [lastName, setLastName] = useState<string>(user?.last_name || "")
    const [phoneNumber, setPhoneNumber] = useState<string>(user?.phone || "")

    useEffect(() => {
        if (user) {
            setFirstName(user.first_name || "")
            setLastName(user.last_name || "")
            setPhoneNumber(user.phone || "")
            setIsOpen(!user.first_name || !user.last_name || !user.phone)
        }
    }, [user, setIsOpen])

    useEffect(() => {
        if (isOpen && setUserNewData)
            setUserNewData({
                first_name: firstName,
                last_name: lastName,
                phone: phoneNumber?.replace(/([( )-])/g, ""), // strip off special characters from masked value
            })
    }, [firstName, isOpen, lastName, phoneNumber, setUserNewData])

    const isInputValid = useMemo(
        () =>
            firstName !== "" &&
            lastName !== "" &&
            phoneNumber !== "" &&
            validatePhoneNumber(phoneNumber),
        [firstName, lastName, phoneNumber]
    )

    return (
        <MbModal
            title="Let us get to know you"
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            allowClose={false}
        >
            <FormControl label="First name*" caption="">
                <Input
                    id="first-name-input"
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
            </FormControl>
            <FormControl label="Last name*" caption="">
                <Input
                    id="last-name-input"
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
            </FormControl>
            <FormControl
                label="Phone number*"
                caption="We will only notify you of your bookings"
            >
                <MaskedInput
                    id="phone-number-input"
                    mask="+1 (999) 999-9999"
                    placeholder="+1 (___) ___-____"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
            </FormControl>
            {updateError && (
                <Notification
                    closeable
                    kind={KIND.negative}
                    overrides={notificationOverrides}
                >
                    Missing or invalid inputs
                </Notification>
            )}
            <PrimaryGradientButton
                fullwidth
                onClick={() => {
                    updateUser({
                        onCompleted: () => setIsOpen(false),
                    })
                }}
                isLoading={isUpdating}
                disabled={!isInputValid}
            >
                Save and continue exploring
            </PrimaryGradientButton>
        </MbModal>
    )
}

export default WelcomeModal
