import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    PropsWithChildren,
} from "react"
import { BaseProvider } from "baseui"

import { LightTheme, DarkTheme } from "~config/theme"

interface IThemeContext {
    theme: ThemeStates
    toggle: () => void
}

export enum ThemeStates {
    LIGHT = "light",
    DARK = "dark",
}

export const ThemeContext = createContext<IThemeContext>({
    theme: ThemeStates.LIGHT,
    toggle: () => null,
})

export const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { theme, toggle } = useTheme()
    const activeTheme = theme === ThemeStates.LIGHT ? LightTheme : DarkTheme

    return (
        <BaseProvider theme={activeTheme}>
            <ThemeContext.Provider value={{ theme, toggle }}>
                {children}
            </ThemeContext.Provider>
        </BaseProvider>
    )
}

export const useThemeContext = () => {
    return useContext(ThemeContext)
}

function useTheme() {
    const [theme, setTheme] = useState<ThemeStates>(ThemeStates.LIGHT)

    useEffect(
        () =>
            setTheme(
                localStorage.getItem("theme")
                    ? (localStorage.getItem("theme") as ThemeStates)
                    : ThemeStates.LIGHT
            ),
        []
    )

    const toggle = () => {
        const value =
            theme === ThemeStates.LIGHT ? ThemeStates.DARK : ThemeStates.LIGHT
        setTheme(value)
        localStorage.setItem("theme", value)
    }

    return { theme, toggle }
}
