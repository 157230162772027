import React from "react"
import { Button, SIZE } from "baseui/button"
import { FaGoogle, FaFacebookF } from "react-icons/fa"

type SocialButtonProps = {
    google?: boolean
    facebook?: boolean
    hasMarginTop?: boolean
    text: string
}

const SocialButton: React.FC<SocialButtonProps> = (props) => {
    const { google, facebook, hasMarginTop, text } = props

    return (
        <Button
            size={SIZE.compact}
            startEnhancer={() => {
                if (google) return <FaGoogle />
                if (facebook) return <FaFacebookF />
                return null
            }}
            overrides={{
                BaseButton: {
                    style: {
                        position: "relative",
                        width: "100%",
                        color: "#FFFFFF",
                        backgroundColor: `
                            ${google ? "#DB3236" : ""}
                            ${facebook ? "#4285F4" : ""}
                        `,
                        marginTop: `${hasMarginTop ? "8px" : 0}`,
                        ":hover": {
                            color: "#FFFFFF",
                            backgroundColor: `
                                ${google ? "#E25050" : ""}
                                ${facebook ? "#5E9DF6" : ""}
                            `,
                        },
                    },
                },
                StartEnhancer: {
                    style: {
                        position: "absolute",
                        top: "50%",
                        left: "10px",
                        transform: "translate(0, -50%)",
                        fontSize: "16px",
                    },
                },
            }}
        >
            {text}
        </Button>
    )
}

export default SocialButton
